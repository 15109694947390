const app = {
  name: 'Dash',
};

const navigation = [
  { name: 'dashboard', title: 'Dashboard' },
  {
    name: 'access-tokens',
    title: 'Access Tokens',
  },
];

export { app, navigation };
