import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import axios from 'axios';

import App from './App.vue';
import router from './router';
import { ATTEMPT } from '@/modules/useAuth';
import AuthLayout from '@/layouts/AuthLayout.vue';
import AppLayout from '@/layouts/AppLayout.vue';

import './assets/main.css';

axios.defaults.baseURL = import.meta.env.VITE_DASH_API_BASE_URL;
axios.defaults.withCredentials = true;

ATTEMPT().then(() => {
  const app = createApp(App);

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
    debug: import.meta.env.VITE_SENTRY_DEBUG === 'true' ?? false,
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE
      ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)
      : 0,
    replaysSessionSampleRate: import.meta.env.VITE_REPLAYS_SESSION_SAMPLE_RATE
      ? parseFloat(import.meta.env.VITE_REPLAYS_SESSION_SAMPLE_RATE)
      : 0,
    replaysOnErrorSampleRate: import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE
      ? parseFloat(import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE)
      : 0,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [import.meta.env.VITE_APP_URL, /^\//],
      }),
      new Sentry.Replay({
        maskAllText:
          import.meta.env.VITE_SENTRY_SESSION_REPLAY_MASK_ALL_TEXT === 'true' ??
          false,
        blockAllMedia:
          import.meta.env.VITE_SENTRY_SESSION_REPLAY_BLOCK_ALL_MEDIA ===
            'true' ?? false,
      }),
    ],
  });

  app.component('AuthLayout', AuthLayout);
  app.component('AppLayout', AppLayout);
  app.use(router);
  app.mount('#app');
});
