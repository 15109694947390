<template>
  <component :is="layout"></component>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const layout = ref<string | null>(null);

watch(
  () => route.meta,
  (to) => {
    layout.value = to.layout === 'AuthLayout' ? 'AuthLayout' : 'AppLayout';
  }
);
</script>
