import axios from 'axios';
import { computed, reactive } from 'vue';
import type { Metrics } from '@/types/Metrics/Metrics';
import type { ListingsMetrics } from '@/types/Metrics/ListingsMetrics';

const state = reactive({
  metrics: <Metrics>{
    listings: <ListingsMetrics>{
      active: 0,
      forSale: 0,
      forRent: 0,
    },
  },
});

const getListingMetrics = computed<ListingsMetrics>(
  () => state.metrics.listings
);

const SET_METRICS = async () => {
  try {
    const { data } = await axios.get('/metrics');
    state.metrics.listings = data.listings;
  } catch (e) {
    console.error(e);
  }
};

export { SET_METRICS, getListingMetrics };
