<template>
  <div>
    <h3 class="text-lg font-medium leading-6 text-gray-900">All Time</h3>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <Stats title="Active Listings" :stat="getListingMetrics.active" />
      <Stats title="Active For Sale" :stat="getListingMetrics.forSale" />
      <Stats title="Active For Rent" :stat="getListingMetrics.forRent" />
    </dl>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import Stats from '@/components/Stats.vue';
import { getListingMetrics, SET_METRICS } from '@/modules/useMetrics';

onMounted(async () => SET_METRICS());
</script>
