<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <RouterView />
  </div>
</template>

<script lang="ts">
import { RouterView } from 'vue-router';

export default {
  name: 'AuthLayout',

  components: {
    RouterView,
  },
};
</script>
