import Router from '@/router';
import { computed, reactive } from 'vue';

const state = reactive({
  currentPageTitle: <string>'',
});

const getCurrentPageTitle = computed<string>(() => state.currentPageTitle);

const isActivePage = (pageName: string) =>
  Router.currentRoute.value.name === pageName;

const SET_CURRENT_PAGE_TITLE = (pageTitle: string) =>
  (state.currentPageTitle = pageTitle);

export { SET_CURRENT_PAGE_TITLE, isActivePage, getCurrentPageTitle };
