import { createRouter, createWebHistory } from 'vue-router';
import { getAuthenticated as authenticated } from '@/modules/useAuth';
import { SET_CURRENT_PAGE_TITLE } from '@/modules/useNavigation';
import LoginView from '../views/auth/LoginView.vue';
import DashboardView from '../views/DashboardView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: { layout: 'AuthLayout' },
    },
    {
      path: '/',
      name: 'dashboard',
      component: DashboardView,
      meta: { title: 'Dashboard: Metrics' },
    },
    {
      path: '/access-tokens',
      name: 'access-tokens',
      component: () => import('../views/AccessTokenView.vue'),
      meta: { title: 'Access Tokens' },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  SET_CURRENT_PAGE_TITLE((to.meta.title as string) ?? '');

  switch (true) {
    case !authenticated.value && to.name !== 'login':
      return next({ name: 'login' });
    case authenticated.value && to.name === 'login':
      return next({ name: 'dashboard' });
    default:
      next();
  }
});

export default router;
