<template>
  <div class="flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-md">
    <img :src="DashLogo" alt="Dash logo" class="h-32 w-32" />
    <h2
      class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
    >
      <span v-text="app.name" /> Login
    </h2>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form
        class="space-y-6"
        action="#"
        method="POST"
        @submit.prevent="LOGIN(form)"
      >
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email address</label
          >
          <div class="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              v-model="form.email"
            />
          </div>
          <div
            v-if="getErrors.email"
            class="mt-2 py-2 px-4 rounded-lg bg-red-200 text-red-900"
          >
            {{ getErrors.email[0] }}
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700"
            >Password</label
          >
          <div class="mt-1">
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              v-model="form.password"
            />
          </div>
          <div
            v-if="getErrors.password"
            class="mt-2 py-2 px-4 rounded-lg bg-red-200 text-red-900"
          >
            {{ getErrors.password[0] }}
          </div>
          <div
            v-if="getErrors.throttle"
            class="mt-2 py-2 px-4 rounded-lg bg-red-200 text-red-900"
          >
            {{ getErrors.throttle[0] }}
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                aria-hidden="true"
              />
            </span>
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { LockClosedIcon } from '@heroicons/vue/20/solid';
import { LOGIN, getErrors } from '@/modules/useAuth';
import { app } from '@/modules/config/dash';
import DashLogo from '@/assets/images/dash-logo.svg';

const form = reactive({
  email: '',
  password: '',
});
</script>
