import axios from 'axios';
import router from '@/router';
import { computed, reactive } from 'vue';
import type { User } from '@/types/Auth/User';
import type { Login } from '@/types/Auth/Login';
import type { LoginAxiosErrors } from '@/types/Axios/LoginAxiosErrors';

const defaultUser = <User>{
  name: '',
  email: '',
};

const defaultErrors = <LoginAxiosErrors>{};

const state = reactive({
  authenticated: <boolean>false,
  user: <User>defaultUser,
  errors: <LoginAxiosErrors>defaultErrors,
});

const getAuthenticated = computed<boolean>(() => state.authenticated);

const getUser = computed<User>(() => state.user);

const getErrors = computed<LoginAxiosErrors>(() => state.errors);

const SET_AUTHENTICATED = (authenticated: boolean) =>
  (state.authenticated = authenticated);
const SET_USER = (user: User) => (state.user = user);

const SET_ERRORS = (errors: LoginAxiosErrors) => (state.errors = errors);

const ATTEMPT = async () => {
  try {
    const response = await axios.get('/user');

    SET_AUTHENTICATED(true);
    SET_USER(response.data.data);

    return response;
  } catch (e: any) {
    SET_AUTHENTICATED(false);
    SET_USER(defaultUser);
  }
};

const LOGIN = async (credentials: Login) => {
  SET_ERRORS(defaultErrors);
  await axios.get('/sanctum/csrf-cookie');

  try {
    await axios.post('/login', credentials);
    await ATTEMPT();
    await router.push({ name: 'dashboard' });
  } catch (e: any) {
    switch (e.response.status) {
      case 422:
        SET_ERRORS(e.response.data.errors);
        break;
      case 429:
        SET_ERRORS({
          throttle: ['Too many login attempts. Please try again later.'],
        });
    }

    console.error(e);
  }
};

const LOGOUT = async () => {
  try {
    const response = await axios.post('/logout');

    SET_AUTHENTICATED(false);
    SET_USER(defaultUser);
    SET_ERRORS(defaultErrors);

    await router.push({ name: 'login' });

    return response;
  } catch (e) {
    console.error(e);
  }
};

export { ATTEMPT, LOGIN, LOGOUT, getAuthenticated, getUser, getErrors };
